<div class="login-page p-8" *ngIf="merchantInfo$ | async as merchantInfo">
  <div class="card">
    <h1 class="card-header text-white">Make collection request on behalf of - {{ merchantInfo.data.merchantName }}</h1>
    <!-- <h4 class="text-red-300">{{ errMessage }}</h4> -->
    <div class="card-body">
      <form (ngSubmit)="processCollection()" [formGroup]="webPaymentForm">
        <div class="form-group sm:mr-0 sm:ml-0 sm:pr-0 sm:pl-0">
          <label class="text-white" for="customerName">Customer Name:</label>
          <input type="text" id="customerName" formControlName="customerName" name="customerName" required />
          <small
            *ngIf="webPaymentForm.controls['customerName'].dirty && webPaymentForm.controls['customerName'].invalid"
            class="invalid-feedback"
          >
            Please enter customer name.
          </small>
        </div>
        <div class="form-group sm:mr-0 sm:ml-0">
          <label class="text-white" for="customerEmail">Customer Email:</label>
          <input type="email" id="customerEmail" formControlName="customerEmail" name="customerEmail" required />
          <small
            *ngIf="webPaymentForm.controls['customerEmail'].dirty && webPaymentForm.controls['customerEmail'].invalid"
            class="invalid-feedback"
          >
            Please enter valid email.
          </small>
        </div>
        <div class="form-group sm:mr-0 sm:ml-0">
          <label class="text-white" for="amount">Amount:</label>
          <input type="number" id="amount" formControlName="amount" name="amount" required />
          <small
            *ngIf="webPaymentForm.controls['amount'].dirty && webPaymentForm.controls['amount'].invalid"
            class="invalid-feedback"
          >
            Please enter a valid amount to request.
          </small>
        </div>
        <div class="form-group sm:mr-0 sm:ml-0">
          <label class="text-white" for="customerMobileNo">Customer Mobile Number:</label>
          <input
            formControlName="customerMobileNo"
            type="text"
            id="customerMobileNo"
            name="customerMobileNo"
            required
            class="pr-10"
            pattern="^[0-9]*$"
            minlength="10"
            maxlength="10"
          />
          <small
            *ngIf="webPaymentForm.controls['customerMobileNo'].dirty && webPaymentForm.controls['customerMobileNo'].invalid"
            class="invalid-feedback"
          >
            Please enter a valid customer mobile number.
          </small>
        </div>
        <!-- <div class="form-group sm:mr-0 sm:ml-0">
          <label for="terminalId" class="block text-sm font-medium text-white">Terminal:</label>
          <select id="terminalId" class="pr-10" id="terminalId" name="terminalId" formControlName="terminalId">
            @for (item of merchantInfo.data.terminals; track item) {
            <option [value]="item.uuid">{{ item.deviceId }}</option>
            }
          </select>
        </div> -->
        <div class="form-group sm:mr-0 sm:ml-0">
          <label for="processor" class="block text-sm font-medium text-white">Sender's Mobile Network:</label>
          <select id="processor" class="pr-10" id="processor" name="processor" formControlName="processor">
            <option value="mtn">MTN</option>
            <option value="telecel">TELECEL</option>
          </select>
        </div>
        <div class="flex justify-between w-full">
          <button class="btn btn-secondary" type="button">Reset</button>
          <button class="btn btn-primary" type="submit" [disabled]="!webPaymentForm.valid">Request Payment</button>
        </div>
      </form>
    </div>
  </div>
</div>
