import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseType } from '@core/enums/response.enum';
import { MerchantService } from '@core/services/merchants/merchant.service';
import { IRequestCollection } from '@core/services/transactions/transactions.interface';
import { TransactionsService } from '@core/services/transactions/transactions.service';
import { Observable, Subscription } from 'rxjs';
import { NotificationModalComponent } from 'src/app/layout/components/notification/notification-modal/notification-modal.component';

@Component({
  selector: 'app-web-merchant-collection',
  templateUrl: './web-merchant-collection.page.html',
  styleUrls: ['./web-merchant-collection.page.scss'],
})
export class WebMerchantCollectionPageComponent implements OnInit {
  webPaymentForm!: FormGroup;
  merchantWebDetails: any = [];
  slug: string = '';
  merchantInfo$!: Observable<any>;
  merchantCode = '';

  constructor(
    private _router: ActivatedRoute,
    private fb: FormBuilder,
    private _merchantService: MerchantService,
    private dialog: MatDialog,
    private readonly transactionService: TransactionsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createWebPaymentForm();
    this._router.paramMap.subscribe((params) => {
      const slug = params.get('slug');
      if (slug) this.getMerchantBySlug(slug);
    });
  }

  createWebPaymentForm() {
    this.webPaymentForm = this.fb.group({
      customerName: ['', Validators.required],
      customerEmail: ['', Validators.required],
      customerMobileNo: ['', Validators.required],
      amount: ['', [Validators.required, Validators.min(0.1)]],
      description: ['Collection'],
      currency: ['GHS'],
      processor: ['', Validators.required],
    });
  }
  get webPaymentFormControl() {
    return this.webPaymentForm.controls;
  }

  getMerchantBySlug(slug: string) {
    this.merchantInfo$ = this._merchantService.getMerchantDetailsBySlug(slug);
    this.merchantInfo$.subscribe({
      next: (value) => {
        this.merchantCode = value.data.merchantCode;
        if (this.merchantCode == '') this.router.navigate(['/under-construction']);
      },
      error: (err) => {
        console.log('could not fetch merchant info', err);
      },
    });
  }

  processCollection() {
    this.openDialog(ResponseType.PROCESSING, 'Collection is being requested.');

    const req: IRequestCollection = {
      merchantId: this.merchantCode,
      amount: Number(this.webPaymentForm.value.amount).toFixed(2).toString(),
      customer: {
        mobileNumber: this.webPaymentForm.value.customerMobileNo,
        name: this.webPaymentForm.value.customerName,
        email: this.webPaymentForm.value.customerEmail,
      },
      processor: this.webPaymentForm.value.processor,
      currency: 'GHS',
      description: 'this is a description',
    };

    this.transactionService.collectionRequest(req).subscribe({
      next: (data) => {
        this.closeModal();
        if (data.status == ResponseType.SUCCESS)
          this.openDialog(ResponseType.SUCCESS, 'Collection successfully requested.');
        this.clearForm();
      },
      error: (err) => {
        this.closeModal();
        // Your additional code here
        this.openDialog(ResponseType.ERROR, `Collection request failed. ${err.error?.message}`);
      },
    });
  }

  clearForm() {
    this.webPaymentForm.reset();
  }

  private openDialog(title: string, message: string): void {
    this.dialog.open(NotificationModalComponent, {
      width: '400px',
      height: '220px',
      data: { title, message },
    });
  }

  closeModal(title?: string, message?: string): void {
    this.dialog.closeAll();
  }
}
