import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ResponseType } from '@core/enums/response.enum';
@Component({
  selector: 'app-notification-modal',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './notification-modal.component.html',
  styleUrl: './notification-modal.component.scss',
})
export class NotificationModalComponent {
  success: ResponseType = ResponseType.SUCCESS;
  error: ResponseType = ResponseType.ERROR;
  processing: ResponseType = ResponseType.PROCESSING;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: ResponseType; message: string; errMessage: string },
    public dialogRef: MatDialogRef<NotificationModalComponent>
  ) {}
  closeModal() {
    this.dialogRef.close();
  }
}
