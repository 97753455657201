import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, of, switchMap } from 'rxjs';
import { environment } from '@env/environment';
import { GetPartnerId, GetToken } from '@core/utils/local-storage-data';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { toQueryString } from '@core/utils/queries-stitches.util';
import { v4 as uuidv4 } from 'uuid';
import { IFundWallet, IRequestCollection } from './transactions.interface';
import { IVerifyOtp } from '../auth/auth.interface';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(private http: HttpClient) {}

  getToken(): string {
    return GetToken();
  }

  getPartnerId(): string {
    return GetPartnerId();
  }

  // const queryString = toQueryString(req);
  // let url = `${baseUrl}/transactions`;
  // if (req.partnerBankId) {
  //   url = `${url}/partners?${queryString}`;
  // } else {
  //   url = `${url}?${queryString}`;
  // }

  // return this.http
  //   .get(url, {
  //     headers: {
  //       Authorization: `Bearer ${this.getToken()}`,
  //     },
  //   })
  //   .pipe(
  //     switchMap((response: any) => {
  //       return of(response.data);
  //     })
  //   );

  getTransactions(req: any): Observable<any> {
    const partnerId = this.getPartnerId();
    const queryString = toQueryString(req);
    let url = `${baseUrl}/transactions`;

    if (partnerId) {
      url = `${url}/partners?${queryString}&partnerBankId=${partnerId}`;
    } else {
      url = `${url}?${queryString}`;
    }

    return this.http
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(
        switchMap((response: any) => {
          return of(response.data);
        })
      );
  }

  merchantCashout(body: any): Observable<any> {
    const idempotencyKey = uuidv4();
    return this.http
      .post(`${baseUrl}/transactions/cashout`, body, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
          'x-idempotency-key': idempotencyKey,
        },
      })
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  requery(id: any): Observable<any> {
    return this.http
      .get(`${baseUrl}/transactions/${id}/re-query`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(
        switchMap((response: any) => {
          return of(response.data);
        })
      );
  }

  reverse(id: string, payload: IVerifyOtp): Observable<any> {
    const idempotencyKey = uuidv4();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
      'x-idempotency-key': idempotencyKey,
    });
    return this.http.post(`${baseUrl}/transactions/${id}/reverse`, payload, { headers }).pipe(
      switchMap((response: any) => {
        return of(response.data);
      })
    );
  }

  transfer(payload: IFundWallet): Observable<any> {
    const idempotencyKey = uuidv4();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
      'x-idempotency-key': idempotencyKey,
    });
    return this.http.post(`${baseUrl}/transactions/transfer/internal`, payload, { headers }).pipe(
      switchMap((response: any) => {
        return of(response.data);
      })
    );
  }

  fundWallet(payload: IFundWallet): Observable<any> {
    const idempotencyKey = uuidv4();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
      'x-idempotency-key': idempotencyKey,
    });
    return this.http.post(`${baseUrl}/transactions/wallet/fund`, payload, { headers }).pipe(
      switchMap((response: any) => {
        return of(response.data);
      })
    );
  }

  getWalletBalance(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.http.get(`${baseUrl}/transactions/wallet/balance`, { headers }).pipe(
      switchMap((response: any) => {
        return of(response.data);
      })
    );
  }

  selfFunding(payload: IRequestCollection): Observable<any> {
    const idempotencyKey = uuidv4();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
      'x-idempotency-key': idempotencyKey,
    });
    return this.http.post(`${baseUrl}/transactions/merchant/self-funding`, payload, { headers }).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  creditWalletManually(merchantId: string, amount: number): Observable<any> {
    const idempotencyKey = uuidv4();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
      'x-idempotency-key': idempotencyKey,
    });
    const params = new HttpParams().set('merchantId', merchantId);

    return this.http.post(`${baseUrl}/transactions/merchant/${merchantId}/fund`, { amount }, { headers }).pipe(
      exhaustMap((response: any) => {
        return of(response);
      })
    );
  }

  collectionRequest(payload: IRequestCollection): Observable<any> {
    const idempotencyKey = uuidv4();
    const headers = new HttpHeaders({
      'x-idempotency-key': idempotencyKey,
      secret: 'YXBpS2V5SW5CYXNlNjQzMzY4MDViNi03MTNiLTQ5Y2ItODM2MS1hMmU4Mjk0NjVlM2M=',
    });
    return this.http.post(`${baseUrl}/transactions`, payload, { headers }).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }
}
