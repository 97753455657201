import { Injectable } from '@angular/core';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import { AuthUtils } from '@core/helpers';
import jwtDecode from 'jwt-decode';
import {
  ClearStorage,
  GetLoginUrl,
  GetRedirectUrl,
  GetRefreshToken,
  GetToken,
  GetUserType,
  RemoveAuthData,
  SetLoginUrl,
  SetMerchantId,
  SetPartnerId,
  SetRedirectUrl,
  SetRefreshToken,
  SetToken,
  SetUserId,
  SetUserType,
} from '@core/utils/local-storage-data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserType } from '@core/constants/users.constant';
import { SmsFor } from '@core/enums/utilility.enum';
import { Router } from '@angular/router';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {
    this._authenticated = false;
  }

  private _authenticated: boolean;

  get authenticated(): boolean {
    return this._authenticated;
  }

  set authenticated(value: boolean) {
    this._authenticated = value;
  }

  getToken(): string {
    return GetToken();
  }

  setToken(token: string): void {
    SetToken(token);
  }

  getRefreshToken(): any {
    return GetRefreshToken();
  }

  setRefreshToken(token: string): void {
    SetRefreshToken(token);
  }

  getUserType(): UserType {
    return GetUserType();
  }

  setUserType(user_type: string): void {
    SetUserType(user_type);
  }

  setUserId(userId: string): void {
    SetUserId(userId);
  }

  setPartnerId(partnerId: string): void {
    SetPartnerId(partnerId);
  }

  setMerchantId(merchantId: string): void {
    SetMerchantId(merchantId);
  }

  setRedirectUrl(url: string) {
    SetRedirectUrl(url);
  }

  getRedirectUrl(): string {
    return GetRedirectUrl();
  }

  setLoginUrl(url: string) {
    SetLoginUrl(url);
  }

  getLoginUrl(): string {
    return GetLoginUrl();
  }

  logout() {
    const redirectUrl = this.getLoginUrl();
    console.log('logout', redirectUrl);
    ClearStorage();
    window.location.href = redirectUrl;
    // this.router.navigateByUrl(redirectUrl);
  }

  requestOtp(requestedFor: SmsFor): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.http.post(`${baseUrl}/auth/otp-request`, { requestedFor }, { headers }).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  validateOtp(requestedFor: SmsFor) {
    this.http.post(`${baseUrl}/auth/otp-request`, requestedFor).pipe(
      switchMap((response: any) => {
        return of(response.data);
      })
    );
  }

  requestResetPassword(data: { password: string; token: string }): Observable<any> {
    return this.http.post(`${baseUrl}/auth/initiate-reset-password`, data).pipe(
      switchMap((response: any) => {
        const { data } = response;
        RemoveAuthData();
        return of(data);
      })
    );
  }

  resetPassword(data: { password: string; token: string }): Observable<any> {
    return this.http.post(`${baseUrl}/auth/complete-reset-password`, data).pipe(
      switchMap((response: any) => {
        const { data } = response;
        RemoveAuthData();
        return of(data);
      })
    );
  }

  login(data: any) {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }
    // console.log(baseUrl);
    // return this.http.post(`${baseUrl}/auth/login`, data);
    // const x = this.http.post(`${baseUrl}/auth/login`, data).pipe(switchMap((e: any) => { return e; }));
    // console.log('xxx',x);
    // return of({});
    ClearStorage();
    return this.http.post(`${baseUrl}/auth/login`, data).pipe(
      switchMap((response: any) => {
        const { data } = response;

        RemoveAuthData();
        this.setToken(data.token);
        // this.setRefreshToken(response.data.refresh);
        const decoded = jwtDecode(data.token);

        // @ts-ignore
        const user_type = decoded.user.role;

        this.setUserType(user_type);

        // @ts-ignore
        this.setUserId(decoded.user.uuid);
        this._authenticated = true;
        return of({ user_type: user_type });
      })
    );
  }

  partnerLogin(data: any, parterName: string): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }
    ClearStorage();
    return this.http.post(`${baseUrl}/auth/login/${parterName}`, data).pipe(
      switchMap((response: any) => {
        const { data } = response;
        RemoveAuthData();
        this.setToken(data.token);
        // this.setRefreshToken(response.data.refresh);
        const decoded = jwtDecode(data.token);
        console.log(decoded);
        // @ts-ignore
        const user_type = decoded.user.role;
        this.setUserType(user_type);

        // @ts-ignore
        this.setUserId(decoded.user.uuid);

        // @ts-ignore
        const partnerId = decoded?.partnerBankId;
        console.log(partnerId);

        if (partnerId) this.setPartnerId(partnerId);

        // @ts-ignore
        const merchantId = decoded?.merchantId;

        if (merchantId) this.setMerchantId(merchantId);

        this._authenticated = true;
        return of({ user_type: user_type });
      })
    );
  }

  signInUsingToken(): Observable<any> {
    // Renew token
    return this.http
      .post(`${baseUrl}/api/auth/token/refresh/`, {
        refresh: this.getRefreshToken(),
      })
      .pipe(
        catchError((err) => {
          console.log('Error renewing token', err);

          // Return false
          return of(false);
        }),
        switchMap((response: any) => {
          if (!response) return of(false);

          console.log('Token renewed', response);

          // Store the access token in the local storage
          this.setToken(response.data.access);

          // Set the authenticated flag to true
          this._authenticated = true;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.getToken()) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.getToken()) && AuthUtils.isTokenExpired(this.getRefreshToken())) {
      return of(false);
    }

    // If the access token exists, and it didn't expire, sign in using it

    if (AuthUtils.isTokenExpired(this.getToken()) && !AuthUtils.isTokenExpired(this.getRefreshToken())) {
      return this.signInUsingToken();
    }

    return of(true);
  }
}
