import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnderconstructionPage } from '@core/ui/pages/underconstruction/underconstruction.page';
import { DashboardPage } from '@pages/home/dashboard/dashboard.page';
import { AuthGuard } from '@core/guards/auth.guard';
import { NoAuthGuard } from '@core/guards/noAuth.guard';
import { LogoutGuard } from '@core/guards/logout.guard';
import { LoginPage } from '@pages/auth/login/login.page';
import { WebMerchantPageComponent } from '@pages/web-merchants/web-merchant.page';
import { WebMerchantCollectionPageComponent } from '@pages/web-merchant-collection/web-merchant-collection.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  // -------------------- Auth Routes --------------------
  {
    path: 'merchant/:slug',
    component: WebMerchantPageComponent,
  },
  {
    path: 'collection/:slug', // temp page to trigger collections
    component: WebMerchantCollectionPageComponent,
  },
  {
    path: 'auth',
    canMatch: [NoAuthGuard],
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'login/:type/:partnerName',
    canMatch: [NoAuthGuard],
    redirectTo: 'auth/login',
  },
  {
    path: 'register',
    canMatch: [NoAuthGuard],
    redirectTo: 'auth/register',
  },
  {
    path: 'logout',
    canMatch: [LogoutGuard],
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  // -------------------- Home Routes --------------------

  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
  },

  // -------------------- Auxiliary Routes --------------------

  {
    path: 'default',
    component: LoginPage,
  },
  {
    path: 'under-construction',
    component: UnderconstructionPage,
  },
  {
    path: '**',
    redirectTo: 'under-construction',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
