export function returnLogoForProcessor(processor: string) {
  switch (processor.toLowerCase()) {
    case 'mtn':
      return 'assets/icons/heroicons/outline/mtn-new-logo.svg';
    case 'telecel':
      return 'assets/icons/heroicons/outline/vodafone.svg';
    case 'airtel':
      return 'assets/icons/heroicons/outline/airtel.svg';
    case 'tigo':
      return 'assets/icons/heroicons/outline/airtel.svg';
    default:
      return 'assets/icons/heroicons/outline/lock-closed.svg';
  }
}

export function getBtnBgColor(status: string): string {
  switch (status) {
    case 'pending':
      return 'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-500';
    case 'failed':
      return 'bg-red-400 hover:bg-red-600 active:bg-red-800';
    case 'successful':
      return 'bg-green-700 hover:bg-green-600 active:bg-green-800';
    default:
      return 'text-black-500'; // default color if status is not recognized
  }
}

export function getStatusColor(status: string): string {
  switch (status) {
    case 'pending':
      return 'text-yellow-500';
    case 'failed':
      return 'text-red-500';
    case 'successful':
      return 'text-green-500';
    default:
      return 'text-black-500'; // default color if status is not recognized
  }
}
