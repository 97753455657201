<div class="mx-auto px-4 py-4 sm:px-8 lg:container" *ngIf="merchantInfo$ | async as merchantInfo">
  <div class="text-center">
    <h2 class="mb-1 text-2xl font-semibold text-gray-700 dark:text-white">
      {{ merchantInfo.data.merchantName | titlecase }}
    </h2>
    <p class="text-sm text-gray-400 dark:text-night-200">{{ 'Address: ' + merchantInfo.data.address }}</p>
  </div>
  <div
    class="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-gray-300 after:mt-0.5 after:flex-1 after:border-t after:border-gray-300 dark:before:border-night-500 dark:after:border-night-500"
  >
    <p class="mx-4 mb-0 text-center text-sm text-gray-400 dark:text-night-400">Customer Details</p>
  </div>

  <form (ngSubmit)="createMerchant()" [formGroup]="webPaymentForm">
    <div class="flex mb-4">
      <div class="w-1/2 mr-4">
        <label for="customerName" class="block text-sm font-medium text-gray-600">Name</label>
        <input
          type="text"
          id="customerName"
          name="customerName"
          class="mt-1 p-2 w-full border rounded-md"
          formControlName="customerName"
        />

        <div *ngIf="webPaymentFormControl['customerName'].invalid && webPaymentFormControl['customerName'].touched">
          <small class="text-danger">Please enter your name</small>
        </div>
      </div>

      <div class="w-1/2 mr-4">
        <label for="customerEmail" class="block text-sm font-medium text-gray-600">Email Address</label>
        <input
          type="text"
          id="customerEmail"
          name="customerEmail"
          class="mt-1 p-2 w-full border rounded-md"
          formControlName="customerEmail"
        />
        <div *ngIf="webPaymentFormControl['customerEmail'].invalid && webPaymentFormControl['customerEmail'].touched">
          <small class="text-danger">Please enter a valid email address</small>
        </div>
      </div>
    </div>
    <div class="flex mb-4">
      <div class="w-1/2 mr-4">
        <label for="customerMobileNo" class="block text-sm font-medium text-gray-600">Mobile Number</label>
        <input
          type="text"
          id="customerMobileNo"
          name="customerMobileNo"
          class="mt-1 p-2 w-full border rounded-md"
          formControlName="customerMobileNo"
        />
        <div
          *ngIf="webPaymentFormControl['customerMobileNo'].invalid && webPaymentFormControl['customerMobileNo'].touched"
        >
          <small class="text-danger">Please enter mobile number</small>
        </div>
      </div>

      <div class="w-1/2 mr-4">
        <label for="currency" class="block text-sm font-medium text-gray-600">Currency</label>
        <select id="currency" name="currency" class="mt-1 p-2 w-full border rounded-md" formControlName="currency">
          <option value="GHANA" [defaultSelected]="">GHS</option>
        </select>
        <div *ngIf="webPaymentFormControl['currency'].invalid && webPaymentFormControl['currency'].touched">
          <small class="text-danger">Please select a currency</small>
        </div>
      </div>
    </div>
    <div class="flex mb-4">
      <div class="w-1/2 mr-4">
        <label for="amount" class="block text-sm font-medium text-gray-600">Transaction Amount</label>
        <input
          type="number"
          id="amount"
          name="amount"
          class="mt-1 p-2 w-full border rounded-md"
          formControlName="amount"
        />
        <div *ngIf="webPaymentFormControl['amount'].invalid && webPaymentFormControl['amount'].touched">
          <small class="text-danger">Please enter amount</small>
        </div>
      </div>

      <div class="w-1/2 mr-4">
        <label for="referenceNo" class="block text-sm font-medium text-gray-600">Reference No.</label>
        <input
          type="text"
          id="referenceNo"
          name="referenceNo"
          class="mt-1 p-2 w-full border rounded-md"
          formControlName="referenceNo"
        />
        <div *ngIf="webPaymentFormControl['referenceNo'].invalid && webPaymentFormControl['referenceNo'].touched">
          <small class="text-danger">Please enter a reference number</small>
        </div>
      </div>
    </div>
    <div class="flex mb-4">
      <div class="w-full mr-4">
        <label for="description" class="block text-sm font-medium text-gray-600">Description</label>
        <input
          type="text"
          id="description"
          name="description"
          class="mt-1 p-2 w-full border rounded-md"
          formControlName="description"
        />
        <div *ngIf="webPaymentFormControl['description'].invalid && webPaymentFormControl['description'].touched">
          <small class="text-danger">Please enter a narration</small>
        </div>
      </div>
    </div>

    <div
      class="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-gray-300 after:mt-0.5 after:flex-1 after:border-t after:border-gray-300 dark:before:border-night-500 dark:after:border-night-500"
    >
      <p class="mx-4 mb-0 text-center text-sm text-gray-400 dark:text-night-400"></p>
    </div>
    <div class="flex mb-4 justify-center">
      <div class="w-1/6 mr-4">
        <button
          type="submit"
          [disabled]="true"
          class="group relative mb-10 flex w-full justify-center rounded-md border border-transparent bg-primary-950 py-2 px-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 hover:bg-primary-800"
        >
          Next
        </button>
      </div>

      <div class="w-1/6">
        <button
          type="button"
          [disabled]="true"
          (click)="clearForm()"
          class="group relative mb-10 flex w-full justify-center rounded-md border border-transparent bg-green-900 py-2 px-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 hover:bg-green-700"
        >
          Reset
        </button>
      </div>
    </div>
  </form>
</div>
