import { Injectable } from '@angular/core';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import { AuthUtils } from '@core/helpers';
import jwtDecode from 'jwt-decode';
import {
  ClearStorage,
  GetRefreshToken,
  GetToken,
  GetUserType,
  SetRefreshToken,
  SetToken,
  SetUserType,
} from '@core/utils/local-storage-data';
import { HttpClient } from '@angular/common/http';
import { UserType } from '@core/constants/users.constant';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  constructor(private http: HttpClient) {
    this._authenticated = false;
  }

  private _authenticated: boolean;

  get authenticated(): boolean {
    return this._authenticated;
  }

  set authenticated(value: boolean) {
    this._authenticated = value;
  }

  getToken(): string {
    return GetToken();
  }

  setToken(token: string): void {
    SetToken(token);
  }

  getRefreshToken(): any {
    return GetRefreshToken();
  }

  setRefreshToken(token: string): void {
    SetRefreshToken(token);
  }

  getUserType(): string {
    return GetUserType();
  }

  setUserType(user_type: string): void {
    SetUserType(user_type);
  }

  logout() {
    ClearStorage();
    window.location.href = '/login';
  }

  getMerchants(req: any): Observable<any> {
    console.log(req);
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }
    let url = `${baseUrl}/merchants?page=${req.pageNumber}&perPage=${req.pageSize}`;
    if (req.partnerBankId) url = `${baseUrl}/merchants?partnerBankId=${req.partnerBankId}`;
    if (req.merchantName) url = `${baseUrl}/merchants?merchantName=${req.merchantName}`;

    return this.http
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(
        switchMap((response: any) => {
          return of(response.data);
        })
      );
  }

  getBanks(): Observable<any> {
    return this.http.get(`${baseUrl}/banks`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }

  getBankBranches(id: string): Observable<any> {
    return this.http
      .get(`${baseUrl}/banks/${id}`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(
        switchMap((response: any) => {
          return of(response.data);
        })
      );
  }

  getPartnerBanks(): Observable<any> {
    return this.http
      .get(`${baseUrl}/banks/partners`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(
        switchMap((response: any) => {
          return of(response.data);
        })
      );
  }

  getMerchantDetailsBySlug(slug: string): Observable<any> {
    return this.http.get<any>(`${baseUrl}/merchants/web/${slug}`);
  }

  createMerchant(body: any): Observable<any> {
    return this.http.post(`${baseUrl}/merchants`, body, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }

  getmerchantById(id: string): Observable<any> {
    return this.http.get(`${baseUrl}/merchants/${id}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }

  getSubmerchantById(id: string): Observable<any> {
    return this.http
      .get(`${baseUrl}/merchants/${id}/sub-merchants`, {
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(
        switchMap((response: any) => {
          return of(response.data);
        })
      );
  }

  createSubMerchant(body: any): Observable<any> {
    return this.http.post(`${baseUrl}/merchants/create-submerchant`, body, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }

  getOrganizationType(): Observable<any> {
    return this.http.get(`${baseUrl}/merchants/categories`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }

  getMerchantById(id: string): Observable<any> {
    return this.http.get(`${baseUrl}/merchants/${id}`, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }

  editMerchant(body: any, id: string): Observable<any> {
    return this.http.put(`${baseUrl}/merchants/${id}`, body, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }
}
